<template>
  <div class="carousell-container">
    <v-btn
      v-if="!has_touch_screen && !is_smaller_than_viewport"
      class="scroll left"
      @click="slide(false)"
      icon
      dark
      color="white"
    >
      <v-icon>
        keyboard_arrow_left
      </v-icon>
    </v-btn>
    <v-btn
      v-if="!has_touch_screen && !is_smaller_than_viewport"
      class="scroll right"
      @click="slide(true)"
      icon
      dark
      color="white"
    >
      <v-icon>
        keyboard_arrow_right
      </v-icon>
    </v-btn>
    <div
      class="carousell-wrapper scrollbar-hidden"
      :id="`${id}`"
      :style="(!has_touch_screen) ? 'overflow-x:hidden;' : ''"
    >
      <slot name="thumbnail" />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'normalized_dist',
    'scroll_factor',
    'scroll_dist',
    'has_touch_screen',
  ],

  data: () => ({
    delta_pos: 0,
    is_smaller_than_viewport: false,
  }),

  methods: {
    slide(isScrollingRight) {
      let c = document.getElementById(this.id); // container
      //we scroll to the "ideal value" of delta_pos
      if(c.scrollLeft <= 0) this.delta_pos = 0; //dirty fix
      c.scrollLeft = (isScrollingRight) ? this.delta_pos + this.scroll_dist : this.delta_pos - this.scroll_dist;
      if(
        (!isScrollingRight &&
          this.delta_pos - this.scroll_dist >= 0) ||
        (isScrollingRight &&
          this.delta_pos + c.clientWidth + this.normalized_dist < c.childElementCount * this.normalized_dist)
      ) {
        this.delta_pos += (isScrollingRight) ? this.scroll_dist : -this.scroll_dist;
      }
      else {
        // console.log(`${(isScrollingRight) ? 'is scrolled to maximum' : 'is scrolled to minimum'}`);
      }
    },
  },

  mounted() {
    if(document.getElementById(this.id)) { // container
      let c = document.getElementById(this.id);
      this.delta_pos = parseInt(c.scrollLeft - (c.scrollLeft % this.scroll_dist));
      this.is_smaller_than_viewport = c.childElementCount * this.normalized_dist < c.clientWidth;
    }
  },
}
</script>

<style lang="css" scoped>
.help {
  border-style: solid;
  border-width: 1px;
}
.carousell-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 220px;
}
.carousell-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap;
  grid-column-gap: calc(var(--grid-space) * 1px);
  overflow-x: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}
.scroll {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  background-color: rgba(127, 127, 127, 0.67);
  opacity: 0.6;
}
.v-btn:hover {
  opacity: 1;
}
.left {
  left: 3px;
}
.right {
  right: 3px;
}
</style>
