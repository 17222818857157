<template>
  <div class="category">
    <v-btn
      outlined
      color="white"
      class="my-button category-btn"
      :href="(cat_id == 0)
        ? getHref('LatestArticles')
        : getCategoryHref(slug)"
      @click.left.prevent="(cat_id === 0)
        ? navigateToRoute('LatestArticles')
        : navigateToCategory(slug)"
    >
      <h1>{{ name }}</h1>
      <div v-if="is_pro" :class="`category-pro-marker ${$vuetify.breakpoint.smAndDown ? 'pro-small' : 'pro-big'}`">
        <p>PRO</p>
      </div>
      <Arrow :style="$vuetify.breakpoint.smAndDown ?  'width: 32px' : 'width: 48px'" />
    </v-btn>
    <div class="category-overflow">
      <slot name="carousell"/>
    </div>
  </div>
</template>

<script>
import Arrow from '@/components/SVG/Arrow.component.vue';
import { Navigation } from '@/mixins/Navigation.mixin.js';

export default {
  components: {
    Arrow,
  },

  mixins: [Navigation, ],

  props: [
    'name',
    'slug',
    'cat_id',
    'is_pro',
  ],
}
</script>

<style lang="css" scoped>
.category-btn {
  margin-bottom: min(20px, 3%);
  overflow: visible;
}
.category-overflow {
  overflow: hidden;
}
.category {
  padding-bottom: min(30px, 5%);
}
.category-pro-marker {
  background-color: #FF3D00;
  border-radius: 5px;
  /* color: #121212; */
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
}
.pro-small {
  width: 40px;
  height: 20px;
  margin-left: 8px;
  font-size: 9pt;
}
.pro-big {
  width: 50px;
  height: 25px;
  margin-left: 16px;
  font-size: 12pt;
}
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
</style>
