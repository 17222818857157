<template lang="html">
  <div id="home-container">
    <div class="home-language-container">
      <div class="home-language">
        <v-select
          v-model="language_input"
          :items="languages"
          dense
          dark
          hide-details
          height="24"
          @change="setLanguage({ language: language_input })"
        />
      </div>
    </div>
    <Category
      v-for="category in categories_computed"
      :key="`category${category.id}`"
      :name="category.name"
      :slug="category.slug"
      :cat_id="category.id"
      :is_pro="category.published=='pro-user'"
    >
    <!-- @categoryClicked="navigateToCategory(category.id)" -->
      <template #carousell>
        <Carousell
          :id="`carousell-${category.id}`"
          :normalized_dist="normalized_dist"
          :scroll_factor="scroll_factor"
          :scroll_dist="scroll_dist"
          :has_touch_screen="has_touch_screen"
        >
          <template #thumbnail>
            <Thumbnail
              v-for="(article, index) in getUObjects({ type: 'articles', ids: category.articles, })"
              :key="`thumbnail${index}`"
              :title="article.title"
              :publish_date="getArticleDate(article)"
              :authors="article.authors"
              :thumbnail="article.featured_image"
              :reference="{ category_slug: `${article.category_slug}`, article_id: `${article.id}`, }"
             />
          </template>
        </Carousell>
      </template>
    </Category>
  </div>
</template>

<script>
import Category from '@/components/Category.component.vue';
import Carousell from '@/components/Carousell.component.vue';
import Thumbnail from '@/components/Thumbnail.component.vue';
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';

export default {
  components: {
    Category,
    Carousell,
    Thumbnail,
  },

  mixins: [ArticleDate, Ultra, Navigation, Knowledge, ],

  computed: {
    categories_computed() {
      return [{
        articles: this.getLatestArticles(),
        id: 0,
        name: (this.getSelectedLanguage() === 'de') ? 'NEUSTE.artikel' : 'LATEST.articles',
        published: 'public',
        slug: 'neuste-artikel',
      }].concat(
        this.getUObjectsFiltered(
          {
            type: 'categories',
            filter:
            {
              published: 'public',
              language: this.getSelectedLanguage(),
            },
          }
        ),
        this.getUObjectsFiltered(
          {
            type: 'categories',
            filter:
            {
              published: 'pro-user',
              language: this.getSelectedLanguage(),
            },
          }
        )
        // .sort(
        //   (a, b) => a.order_index - b.order_index
        // )
      ).sort((a, b) => a.order_index - b.order_index);
    },
  },

  data: () => ({
    categories: {
      id: -1,
      name: '',
      slug: '',
      articles: { },
    },
    normalized_dist: 320,
    scroll_factor: 1,
    scroll_dist: 320,
    has_touch_screen: false,

    languages: [],
    language_input: '',
  }),

  methods: {
    setScrollParameters() {
      // every carousell in each category shares the same scroll_factor and scroll_dist
      let c = document.getElementById('home-container');
      let width = getComputedStyle(document.body).getPropertyValue('--thumbnail-width-static');
      let rowSpace = getComputedStyle(document.body).getPropertyValue('--grid-space');

      // calculate the items per row
      let freeSpace = c.clientWidth;
      let itemsPerRow = 1;
      if(freeSpace >= width && (freeSpace > 0 && width > 0)) {
        itemsPerRow = parseInt(freeSpace / width); //wie viele elemente wuerden ohne space reinpassen (itemsPerRow)
        if((itemsPerRow * width + (itemsPerRow - 1) * rowSpace) > freeSpace) //falls es mit space freeSpace sprengt
          itemsPerRow--; //nimm ein element weniger
      }

      this.normalized_dist = parseInt(width) + parseInt(rowSpace);
      this.scroll_factor = Math.max(parseInt(itemsPerRow * 0.75), 1);
      this.scroll_dist = this.normalized_dist * this.scroll_factor;
    },

    loadCategories() {
      this.requestUObject({
        url: [
          { categories: '' },
          { showWithEntitiesNormalized: '' },
        ],
        url_suffix: `&published=public&language=${this.getSelectedLanguage()}`
      });
      if(this.hasPermission('CMS Pro READ')) {
        console.log("!!!!!");
        this.requestUObject({
          url: [
            { categories: '' },
            { showWithEntitiesNormalized: '' },
          ],
          url_suffix: `&published=pro-user&language=${this.getSelectedLanguage()}`
        });
      }
    },
  },

  created() {
    this.loadCategories();

    // checks if we operate on a mobile device
    if ("maxTouchPoints" in navigator) {
        this.has_touch_screen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        this.has_touch_screen = navigator.msMaxTouchPoints > 0;
    }

    this.languages = this.getLanguages();
    this.language_input = this.getSelectedLanguage();
  },

  mounted() {
    if(document.getElementById('home-container')) { // container
      this.setScrollParameters();
    }

    window.addEventListener('resize', this.setScrollParameters);
  },

  beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.setScrollParameters);
    next();
  },
}
</script>

<style lang="css" scoped>
svg {
  width: 50px;
  height: 50px;
}
.help {
  border-style: solid;
  border-width: 1px;
}
.home-language {
  width: 64px;
}
.home-language-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
